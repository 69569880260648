.AddGroup .lander {
  padding: 80px 0;
  text-align: center;
}

.AddGroup .lander h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.AddGroup form {
  margin: 0 auto;
  max-width: 320px;
}